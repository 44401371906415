.descripcion-testimonios::-webkit-scrollbar-corner {
  background-color: #909090;
}
.descripcion-testimonios::-webkit-scrollbar-button {
      background-color: rgba(146, 148, 151, 0.46);
}
.descripcion-testimonios::-webkit-scrollbar {
      width: 9px;
}
.descripcion-testimonios::-webkit-scrollbar-track {
      background-color: #f4f4f4;
}
.descripcion-testimonios::-webkit-scrollbar-thumb {
      background-color: rgba(187, 189, 194, 0.2);
}
.descripcion-testimonios::-moz-scrollbar-corner {
  background-color: #909090;
}
.descripcion-testimonios::-moz-scrollbar-button {
      background-color: rgba(146, 148, 151, 0.46);
}
.descripcion-testimonios::-moz-scrollbar {
      width: 9px;
}
.descripcion-testimonios::-moz-scrollbar-track {
      background-color: #f4f4f4;
}
.descripcion-testimonios::-moz-scrollbar-thumb {
      background-color: rgba(187, 189, 194, 0.2);
}
.descripcion-testimonios::-ms-scrollbar-button {
      background-color: rgba(146, 148, 151, 0.46);
}
.descripcion-testimonios::-ms-scrollbar {
      width: 9px;
}
.descripcion-testimonios::-ms-scrollbar-track {
      background-color: #f4f4f4;
}
.descripcion-testimonios::-ms-scrollbar-thumb {
      background-color: rgba(187, 189, 194, 0.2);
}
.fila-contedor{
    border: 1px solid #ddd;
    border-radius: 8px;
}
.modalidad {
    -ms-transform: translateY(25%);
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    position: relative;
    top: 0;

}
.contenido-modalida{
    border-right: 1px solid #ddd;
     font-size: 1.5rem;
}
.card-text{
    font-size: .9rem;
}
.boton-registro{
    position: absolute;
    bottom: 20px;
    right: 25px
}
.row-title-icon {
    margin: 0 auto 3rem!important;
    padding: 0 !important;
}
.icon-lapiz {
    position: absolute;
    left: 48px;
    top: 28px;
}
.row-title-icon .content-icon-inside-globo {
    top: 10px;
    left: 0;
}
.show{
    display: block !important;
}
.titulo-EP{
    font-size: 2.6rem;
    padding-top: 10px;
}
.titulo-principal-evento{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}
.row-title-icon .icon-inside-globo {
    max-width: 3.5rem;
    position: absolute;
    margin-left: -61px;
    margin-top: 10px;
}
.descripcion-asesores{
    line-height: 2;
}
.descripcion-asesores ul li a{
    color: #080C38 !important;
    font-family: Rubik-SemiBold;
}
.descripcion-asesores ul li{
    margin-bottom: 1rem;
}
.container-paises .row-title-icon .icon-inside-globo {
    max-width: 3.5rem;
    position: absolute;
    margin-left: -55px;
    margin-top: 12px;
}
.container-paises .colunmas{
    margin-bottom: 30px;
}
.rowTituloEP .icon-inside-globo {
    max-width: 3.5rem;
    position: absolute;
    margin-left: -14px;
    margin-top: -73px;
}
.row-title-icon .icon-inside-globo {
    max-width: 3.5rem;
    position: absolute;
   margin-left: -62px;
    margin-top: 10px;
}
.container-noticias .row-title-icon .icon-inside-globo {
    max-width: 3.5rem;
    position: absolute;
    margin-left: -61px;
    margin-top: 10px;
}
.programas-titulo-verde{
    font-size: 1.1rem;
    font-weight: 700;
}
.programas-nombre{
    font-size: 1.8rem;
    line-height: 1;
}
.programas-texto{
    font-size: 1.12rem;
    font-weight: 500;
}
.content-programa {
    border-radius: 10px !important;
    box-shadow: 2px 2px 10px 2px #a5a5a5;
}
.li-porque-asistir{
    width: 488px;
}
.content-programa:hover {
    outline: 2px solid #ddd;
}

.content-programa .img-programa {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    border-radius: 10px!important;
}
#bannersEscritorioPromocional {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 254px;
    border-radius: 0 0.5rem 0.5rem 0;
}
.titulo-bannerP{
    font-size: 1.4rem;
}
@media only screen and (max-width: 1600px) {

    #bannersEscritorioPromocional{
         height: 235px;
     }
}
@media only screen and (max-width: 1400px) {

    .titulo-EP {
        font-size: 2.4rem !important;
    }
     #bannersEscritorioPromocional {
        height: 210px;
    }

    .titulo-bannerP {
        font-size:1.2rem;
    }
    .rowTituloEP .icon-inside-globo{
        margin-left: -5px;
    }
    .li-porque-asistir {
        width: 410px;
    }
}
@media only screen and (max-width: 1366px) {
    .titulo-bannerP {
        font-size: 1rem;
    }
}
@media only screen and (max-width: 1280px) {
     #bannersEscritorioPromocional {
        height: 200px;
    }
}
@media only screen and (max-width: 1200px) {
    .row-title-icon {
        width: 100%;
    }
    .titulo-EP {
        font-size: 2.2rem !important;
    }
     #bannersEscritorioPromocional {
        height: 200px;
    }
     .icon-globo-text{
        max-width: 80% !important;
     }
     .icon-lapiz{
         width: 40px;
         left: 46px;
         top: 25px;
     }
     .rowTituloEP .icon-inside-globo {
        margin-left: -8px;
    }
    .li-porque-asistir {
        width: 330px;
    }
}
@media only screen and (max-width: 1100px) {
     #bannersEscritorioPromocional {
        height: 200px;
    }
}
/*********      1024     **********/

@media (max-width: 1024px) {
     #bannersEscritorioPromocional {
        height: 240px;
    }
}
@media only screen and (max-width: 991px) {

    .titulo-EP {
        font-size: 2.05rem !important;
    }
     #bannersEscritorioPromocional {
        height: 255px;
    }
    .rowTituloEP .icon-inside-globo {
        margin-left: -13px;
    }
    .li-porque-asistir {
        width: 350px;
    }
    .descripcion-asesores{
        line-height: 1.8;
    }
}
@media only screen and (max-width: 900px) {

    #bannersEscritorioPromocional {
        height: 195px;
    }
    .icon-lapiz {
        position: absolute;
        left: 38px;
        top: 27px;
        width: 40px;
    }
}
@media (max-width: 768px) {
    #bannersEscritorioPromocional {
        height: 136px;
    }
    .icon-lapiz {
        left: 26px;
        top: 26px;
    }
}
@media only screen and (max-width: 767px) {
    .row-title-icon {
        width: 100%;
    }
    .titulo-EP {
        font-size: 1.9rem !important;
    }
    #bannersEscritorioPromocional {
        background-position: top;
        margin: 0 10px;
        border-radius: 0;
        height: 401px;
    }
    .icon-lapiz{
        width: 50px;
    }
    .boton-registro{
        display: none;
    }
    .boton-movil{
         display: block !important;
    }
    .card-title{
        font-size: 1.35rem;
    }
    .card-text {
        font-size: 1rem !important;
    }
    .modalidad {
        font-size: 1.35rem;
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
        top: 0px;
        margin: 20px 0 20px;
    }
    .contenido-modalida{
        border-bottom: 1px solid #ddd;
        border-right: none;
    }
     .rowTituloEP .icon-inside-globo {
        max-width: 2.8rem ;
        margin-left: -12px;
        margin-top: -68px;
    }
     .titulo-principal-evento {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }
    .li-porque-asistir {
        width: 290px;
    }
    .pt-6 {
        padding-top: 3rem !important;
        padding-bottom: 2rem !important;
    }
    .row-title-icon {
        margin: 0 auto 1rem !important;

    }
}
@media only screen and (max-width: 667px) {
    .rowTituloEP .icon-inside-globo {
        max-width: 2.8rem ;
        margin-left: -12px;
        margin-top: -68px;
    }
}
@media only screen and (max-width: 576px) {

    .row-title-icon .icon-globo {
        max-width: 90%;
    }
    .titulo-EP {
        font-size: 1.8rem !important;
    }
    .content-programa .px-4{

        padding-right: 1rem!important;
        padding-left: 1rem!important;

    }
    #bannersEscritorioPromocional {
        height: 300px;
    }
    .icon-lapiz {
        max-width: 75% !important;
        margin-top: -20px;
        left: 28px;
        top: 47px;
    }
    .rowTituloEP .icon-inside-globo {
        max-width: 2.8rem;
        margin-left: -22px;
        margin-top: -68px;
    }
    .porque-asistit-webinar{
        font-size: 1.3rem !important;
    }
    .li-porque-asistir {
        width: 460px;
    }
    .titulos-principal-testimonio {
        right: 0 !important;
        top: 0 !important;
    }
}
@media only screen and (max-width: 547px) {
    .li-porque-asistir {
        width: 408px;
    }
}
@media only screen and (max-width: 490px) {

    .titulo-EP {
        font-size: 1.6rem !important;
    }
    .row-title-icon .container-fluid {
        padding:0 !important;
    }
    .programas-nombre {
        font-size: 2rem;
    }
    #bannersEscritorioPromocional {
        height: 280px;
    }
    .icon-lapiz {
        left: 28px;
        top: 48px;
        width: 45px;
    }
    .rowTituloEP .icon-inside-globo {
        margin-left: -23px;
        margin-top: -72px;
    }
    .li-porque-asistir {
        width: 380px;
    }
    .titulo-principal-evento{
        margin-top: 0 !important;
    }
}
@media only screen and (max-width: 480px) {
    .li-porque-asistir {
        width: 362px;
    }
}
@media only screen and (max-width: 465px) {
    .li-porque-asistir {
        width: 350px;
    }
}
@media only screen and (max-width: 452px) {
    .li-porque-asistir {
        width: 330px;
    }
}
@media only screen and (max-width: 425px) {

    .titulo-EP {
        font-size: 1.5rem !important;
    }
     #bannersEscritorioPromocional {
        height: 250px;
    }
    .icon-lapiz {
        left: 26px;
        top: 46px;
        width: 37px;
    }
    .rowTituloEP .icon-inside-globo {
        max-width: 2.9rem;
        margin-left: -24px;
        margin-top: -73px;
    }
    .li-porque-asistir {
        width: 315px;
    }
}
@media only screen and (max-width: 393px) {
    .li-porque-asistir {
        width: 278px;
    }
}
@media only screen and (max-width: 384px) {

    .titulo-EP {
        font-size: 1.35rem !important;
        padding-top: 0;
    }
     #bannersEscritorioPromocional {
        height: 230px;
    }
    .icon-lapiz {
        left: 24px;
        top: 43px;
        width: 35px;
    }
    .card-title {
        font-size: 1.25rem;
    }
    .card-text {
        font-size: .9rem !important;
    }
    .modalidad {
        font-size: 1.2rem;
    }
    .rowTituloEP .icon-inside-globo {
        max-width: 2.7rem;
        margin-left: -22px;
        margin-top: -68px;
    }
    .li-porque-asistir {
        width:270px;
    }
}
@media only screen and (max-width: 360px) {
    .icon-lapiz {
        left: 24px;
        top: 43px;
        width: 30px;
    }
    .rowTituloEP .icon-inside-globo {
        max-width: 2.5rem;
        margin-left: -21px;
        margin-top: -64px;
    }
     .li-porque-asistir {
        width:250px;
    }
     .programas-nombre {
        font-size: 1.5rem;
     }
}
@media only screen and (max-width: 320px) {

    .titulo-EP {
        font-size: 1.2rem !important;
    }
    .programas-nombre {
        font-size: 1.8rem;
        line-height: 1;
    }
    .programas-texto {
        font-size: 1rem;
    }
    #bannersEscritorioPromocional {
        height: 200px;
    }
    .icon-lapiz {
        left: 23px;
        top: 43px;
        width: 25px;
    }
      .card-title {
        font-size: 1.15rem;
    }
    .card-text {
        font-size: .85rem !important;
    }
    .modalidad {
        font-size: 1.15rem;
    }
    .rowTituloEP .icon-inside-globo {
        max-width: 2.2rem;
        position: absolute;
        margin-left: -18px;
        margin-top: -54px;
    }
    .li-porque-asistir {
        width: 225px;
    }
}